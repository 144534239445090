<template>
  <div class="programAdd">
    <div class="title">
      <div class="top" @click="fanTitle">评分页广告</div>
      <i class="el-icon-arrow-right"></i>
      <div class="bnt">{{ can ? '活动详情' : '添加活动' }}</div>
    </div>
    <div class="from">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="活动名称" prop="name">
          <el-input :disabled="can ? true : false" v-model="ruleForm.name" placeholder="请填写活动名称"></el-input>
        </el-form-item>
        <el-form-item label="活动日期" prop="time">
          <el-date-picker
              :disabled="can ? true : false"
              v-model="ruleForm.time"
              style="width: 100%;"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="投放范围" prop="range">
          <el-radio-group v-model="ruleForm.range" v-if="!can">
            <el-radio :label="0" v-if="user_id === 0">全国</el-radio>
            <el-radio :label="1">部分场所</el-radio>
          </el-radio-group>
          <span v-else>{{ ruleForm.range == 0 ? '全国' : '部分场所' }}</span>
        </el-form-item>
        <el-form-item label="品牌" v-if="user_id==0&&!can && ruleForm.range == 1">
          <el-select v-model="ruleForm.ping" placeholder="全部" @change="pingClik">
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in pinglist" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放场所" prop="place" v-if="ruleForm.range == 1">
          <el-select :disabled="can ? true : false" v-model="ruleForm.place" placeholder="请选择投放广告位" style="width: 100%;"
                     multiple collapse-tags>
            <el-option v-for="(item,index) in changData" :key="index" :label="item.name"
                       :value="item.premises_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动类型">
          <el-radio-group v-model="ruleForm.type" v-if="!can">
            <el-radio :label="1">图片</el-radio>
            <el-radio :label="2">视频</el-radio>
            <el-radio :label="0" v-if="user_id === 0">内部互动</el-radio>
          </el-radio-group>
          <span v-else>  {{
              ruleForm.type == 0 ? '内部互动' : ruleForm.type == 1 ? '图片' : ruleForm.type == 2 ? '视频' : ''
            }} </span>
        </el-form-item>
        <el-form-item label="上传活动素材" v-if="!can">
          <video width="320" height="240" autoplay="autoplay" :src="ruleForm.url" controls v-if="ruleForm.type == 2">
          </video>
          <a href="javascript:;" class="file" v-show="ruleForm.type == 2">
            上传视频
            <input type="file" name="" id="" @change="uploadFileFn($event)">
          </a>
          <el-upload
              v-show="ruleForm.type != 2"
              class="avatar-uploader"
              action="#"
              :http-request="httpRequest"
              :show-file-list="false">
            <img v-if="ruleForm.url" :src=" ruleForm.url" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" v-loading="loading"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传活动素材" v-else>
          <video width="320" height="240" autoplay="autoplay" :src="ruleForm.url" controls v-if="ruleForm.type == 2">
          </video>
          <div v-else>
            <img v-if="ruleForm.url" :src=" ruleForm.url" class="avatar">
          </div>

        </el-form-item>
        <el-form-item label="活动显示时长" prop="lengthS">
          <el-input-number :disabled="can ? true : false" v-model="ruleForm.lengthS" :min="1" :max="20"
                           label="请输入时长"></el-input-number>
          <!-- <el-input   v-model="ruleForm.lengthS" placeholder="请输入时长"></el-input> --> 
        </el-form-item>
        <div v-if="ruleForm.type == 2">
          <el-form-item label="视频播放音量" prop="volume">
            <el-input :disabled="can ? true : false" v-model="ruleForm.volume" placeholder="请输入广告音量" oninput="if(value>100)value='';if(value<=0)value=''"></el-input>
          </el-form-item>
        </div>
        <div v-else-if="ruleForm.type == 0">
          <el-form-item label="二维码内容" prop="qerCont">
            <el-input :disabled="can ? true : false" v-model="ruleForm.qerCont" placeholder="请输入二维码内容"></el-input>
          </el-form-item>
          <el-form-item label="二维码X坐标" prop="qerX">
            <el-input :disabled="can ? true : false" v-model="ruleForm.qerX" placeholder="请输入二维码x坐标"></el-input>
          </el-form-item>
          <el-form-item label="二维码Y坐标" prop="qerY">
            <el-input :disabled="can ? true : false" v-model="ruleForm.qerY" placeholder="请输入二维码y坐标"></el-input>
          </el-form-item>
          <el-form-item label="二维码长度" prop="qerL">
            <el-input :disabled="can ? true : false" v-model="ruleForm.qerL" placeholder="请输入二维码长度"></el-input>
          </el-form-item>
          <el-form-item label="二维码宽度" prop="qerW">
            <el-input :disabled="can ? true : false" v-model="ruleForm.qerW" placeholder="请输入二维码宽度"></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">{{ can ? '返回' : '确定' }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {storeList, addScoreAd, scoreAdDetail,brandList, editScoreAd} from '../../api/index'

export default {
  data() {
    return {
      pinglist:[],
      user_id: '',
      type: '',
      manDta: JSON.parse(localStorage.getItem('company_list')),
      changData: [],
      can: false,
      ruleForm: {
        ping:'',
        stores: '',
        activity_id: 4,
        name: '',
        time: '',
        range: 0,
        place: [],
        charge: '',
        type: 1,
        lengthS: '',
        volume: '',
        qerCont: '',
        qerX: '',
        qerY: '',
        qerL: '',
        qerW: '',
        imgUrl: '',
        url: ''
      },
      rules: {
        stores: [
          {required: true, message: '请选择门店', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请输入广告名称', trigger: 'blur'},
        ],
        time: [
          {required: true, message: '请选择时间', trigger: 'blur'}
        ],
        charge: [
          {required: true, message: '请输入费用', trigger: 'blur'},
        ],
        lengthS: [
          {required: true, message: '请输入时长', trigger: 'blur'}
        ],
        qerCont: [
          {required: true, message: '请输入二维码内容', trigger: 'blur'}
        ],
        qerX: [
          {required: true, message: '请输入二维码X轴', trigger: 'blur'}
        ],
        qerY: [
          {required: true, message: '请输入二维码Y轴', trigger: 'blur'}
        ],
        qerL: [
          {required: true, message: '请输入二维码长度', trigger: 'blur'}
        ],
        qerW: [
          {required: true, message: '请输入二维码宽度', trigger: 'blur'}
        ],
        videoUrl: [
          {required: true, message: '请上传视频', trigger: 'change'},
        ]
      },
      loading: false,
      imgUrl: "",
      titleName: ''
    }
  },
  created() {
    this.user_id = JSON.parse(localStorage.getItem('user_id'))
    if (this.user_id == 0) {
      this.ruleForm.range = 0
      this._brandList()
      // this._storeList(this.$route.query.company_id)
    } else {
      this.ruleForm.range = 1
      this.changData = JSON.parse(localStorage.getItem('store_list'))
    }
    // /admin/ad/appletAdDetail
    if (this.$route.query.id) {
      console.log(this.$route.query)
      this.titleName = this.$route.query.store_activity_name
      this.ruleForm.name = this.$route.query.store_activity_name
      this._scoreAdDetail()
      this.can = true
    }
  },
  methods: {
    // 品牌模块
    _brandList() {
      brandList().then(Er => {
        if (Er.data && Er.data.length > 0) {
          console.log(Er)
          this.pinglist = Er.data
          this.ruleForm.ping=Er.data[0].name
          this._storeList(Er.data[0].id)
        }
      })
    },
    //切换品牌
    pingClik(val) {
      this.ruleForm.place= []
      this._storeList(val)
    },
    //获取门店
    _storeList(row) {
      storeList({
        brand_id: row
      }).then(Er => {
        if (Er.return_code == 200) {
          if (Er.data && Er.data.length > 0) {
            this.changData = Er.data
          } else {
            this.changData = []
          }
        }
      })
    },
    //获取详情
    _scoreAdDetail() {
      console.log(111)
      scoreAdDetail({store_activity_id: this.$route.query.id}).then(er => {
        if (er.return_code == 200) {
          let row = er.data
          console.log(row)
          let arr = row.premises_id.split(',')
          this.ruleForm = {
            activity_id: row.activity_id,
            name: this.titleName,
            time: [row.begin_date, row.end_date],
            range: row.range,
            charge: row.price,
            type: row.type,
            place: arr,
            url: row.url,
            lengthS: row.show_duration,
            qerX: row.location_x,
            qerY: row.location_y,
            qerL: row.height,
            qerW: row.width,
            volume: row.volume,
            qerCont: row.qrcode_url
          }
        }
      })
    },
    // 返回上一页
    fanTitle() {
      this.$router.push('/score')
    },
    //提交
    submitForm(formName) {
      let _this = this
      if (this.can) {
        _this.$router.push('/score')
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
          _this.can ? _this._editScoreAd() : _this._addScoreAd()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //修改
    _editScoreAd() {
      let _this = this
      let n = ''
      if (_this.ruleForm.place && _this.ruleForm.place.length > 0) {
        _this.ruleForm.place.map((er, index) => {
          let c = index == _this.ruleForm.place.length - 1 ? er : er + ','
          n += c
        })
      }
      let c = ''
      if (_this.ruleForm.range == 0) {
        c = ''
      } else {
        c = n
      }
      let can = {
        ad_id: this.$route.query.id,
        activity_id: _this.ruleForm.activity_id,
        name: _this.ruleForm.name,
        begin_date: _this.ruleForm.time[0],
        end_date: _this.ruleForm.time[1],
        range: _this.ruleForm.range,
        premises_id: c,
        type: _this.ruleForm.type,
        url: _this.ruleForm.url,//素材地址+二维码内容
        show_duration: _this.ruleForm.lengthS,
        location_x: _this.ruleForm.qerX,
        location_y: _this.ruleForm.qerY,
        width: _this.ruleForm.qerW,
        height: _this.ruleForm.qerL,
        volume: _this.ruleForm.volume,
        qrcode_url: _this.ruleForm.qerCont
      }

      editScoreAd(can).then(er => {
        if (er.return_code == 200) {
          _this.$router.push('/score')
          _this.$message({
            message: '编辑成功',
            type: 'success'
          });
        }
      })
    },
    // 新增
    _addScoreAd() {
      let _this = this
      let n = ''
      if (_this.ruleForm.place && _this.ruleForm.place.length > 0) {
        _this.ruleForm.place.map((er, index) => {
          let c = index == _this.ruleForm.place.length - 1 ? er : er + ','
          n += c
        })
      }
      let c = ''
      if (_this.ruleForm.range == 0) {
        c = ''
      } else {
        c = n
      }
      let can = {
        activity_id: _this.ruleForm.activity_id,
        name: _this.ruleForm.name,
        begin_date: _this.ruleForm.time[0],
        end_date: _this.ruleForm.time[1],
        range: _this.ruleForm.range,
        premises_id: c,
        type: _this.ruleForm.type,
        url: _this.ruleForm.url,//素材地址+二维码内容
        show_duration: _this.ruleForm.lengthS,
        location_x: _this.ruleForm.qerX,
        location_y: _this.ruleForm.qerY,
        width: _this.ruleForm.qerW,
        height: _this.ruleForm.qerL,
        volume: _this.ruleForm.volume,
        qrcode_url: _this.ruleForm.qerCont
      }
      addScoreAd(can).then(er => {
        if (er.return_code == 200) {
          _this.$router.push('/score')
          _this.$message({
            message: '添加成功',
            type: 'success'
          });
        }
      })
    },
    // 上传
    httpRequest(file) {
      let _this = this
      console.log(this.ruleForm)
      if (this.ruleForm.type == 1 || this.ruleForm.type == 0) {//图片
        console.log(111)
        if (_this.checkFile(file.file, 'image')) {
          _this.loading = true
          _this.UpHttp(file.file, 'image').then(er => {
            if (er.data.return_code == 200) {
              console.log(er.data.data.url)
              _this.ruleForm.url = er.data.data.url
              _this.imgUrl = er.data.data.url
              _this.loading = false
            }
          })
        }
      }
    },
    //上传视频
    uploadFileFn(e) {
      let _this = this
      let file = e.target.files[0];
      console.log(file)
      if (_this.checkFile(file, 'video')) {
        _this.UpHttp(file, 'image').then(er => {
          if (er.data.return_code == 200) {
            _this.ruleForm.url = er.data.data.url
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.programAdd {
  .title {
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    border-bottom: 1px solid #e6e6e6;

    > .top {
      &:hover {
        cursor: pointer;
        color: #409EFF;
      }
    }

    > i {
      margin: 0px 5px;
    }

    > .bnt {
      font-weight: 500;
    }
  }

  .from {
    padding: 20px 0px;
    width: 55%;
  }
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .avatar-uploader-icon .el-loading-spinner {
  top: 0%;
  margin-top: 0px;
  width: 100%;
  text-align: center;
  position: absolute;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.file {
  position: relative;
  display: inline-block;
  background: #D0EEFF;
  border: 1px solid #99D3F5;
  border-radius: 4px;
  padding: 4px 12px;
  overflow: hidden;
  color: #1E88C7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
}

.file input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}

.file:hover {
  background: #AADFFD;
  border-color: #78C3F3;
  color: #004974;
  text-decoration: none;
}
</style>